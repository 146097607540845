<template>
  <section class="personalizacion-interfaz">
    <div
      class="
        personalizacion-intranet__bottom-top
        d-flex
        align-items-center
        flex-wrap
        justify-content-between
      "
    >
      <div class="d-flex align-items-center">
        <h2 class="personalizacion-intranet__bottom-title m-0">
          Selecciona el <strong>color principal</strong> para tu
          <strong>Linkiwork</strong> y añade el <strong>logotipo</strong> e
          <strong>isotipo</strong> de tu empresa.
        </h2>
      </div>
    </div>

    <div class="personalizacion-interfaz__steps-wrapper">
      <div class="personalizacion-interfaz__container-one">
        <h3 class="personalizacion-interfaz__first-title">
          Selecciona tu color principal
        </h3>
        <div class="personalizacion-interfaz__colors-container ms-2">
          <div class="personalizacion-interfaz__colors-background">
            <button
              class="personalizacion-interfaz__colors"
              v-for="(c, i) in colors"
              :key="i"
              :style="{ backgroundColor: c }"
              @click="selectColor(c, i)"
            >
              <font-awesome-icon
                v-show="show_selected === i"
                class="personalizacion-interfaz__colors-check"
                icon="check"
              ></font-awesome-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="personalizacion-interfaz__container-two">
        <div class="personalizacion-interfaz__container-two--wrapper">
          <div class="d-flex align-items-center">
            <h3 class="personalizacion-interfaz__second-title">
              Añade tu Logotipo para Web
            </h3>
            <img
              class="personalizacion-interfaz__pc-img ms-2"
              src="@/assets/img/mac_grey.svg"
              alt=""
            />
            <div
              class="personalizacion-intranet__info ms-2"
              content="El formato de imagen debe ser PNG sin fondo"
              v-tippy="{ arrow: true }"
            >
              ?
            </div>
          </div>
          <div class="personalizacion-interfaz__upload-logotipo">
            <div
              class="personalizacion-interfaz__upload-component"
              content="Carga tu imagen en formato PNG, peso máximo 2 MB"
              v-tippy="{ arrow: true }"
            >
              <UploadImg />
            </div>
            <p class="text-end mb-0">Vista previa</p>
          </div>
        </div>
        <div class="personalizacion-interfaz__container-two--wrapper">
          <div class="d-flex align-items-center">
            <h3 class="personalizacion-interfaz__second-title">
              Añade tu Logotipo para App
            </h3>
            <img
              class="personalizacion-interfaz__mobile-img ms-2"
              src="@/assets/img/iphone_grey.svg"
              alt=""
            />
            <div
              class="personalizacion-intranet__info ms-2"
              content="El formato de imagen debe ser PNG sin fondo"
              v-tippy="{ arrow: true }"
            >
              ?
            </div>
          </div>
          <div class="personalizacion-interfaz__upload-logotipo">
            <div
              class="personalizacion-interfaz__upload-component"
              content="Carga tu imagen en formato PNG, peso máximo 2 MB"
              v-tippy="{ arrow: true }"
            >
              <UploadImg />
            </div>
            <p class="text-end mb-0">Vista previa</p>
          </div>
        </div>
      </div>
      <div class="personalizacion-interfaz__container-three">
        <div class="d-flex">
          <h3 class="personalizacion-interfaz__third-title">
            Añade tu Isotipo
          </h3>
          <div class="personalizacion-intranet__info ms-2">?</div>
          <IsotipoInfo />
        </div>
        <div class="personalizacion-interfaz__upload-isotipo">
          <div class="personalizacion-interfaz__isotipo-container">
            <div
              class="personalizacion-interfaz__component-upload"
              content="Carga tu imagen en formato PNG, peso máximo 2 MB"
              v-tippy="{ arrow: true }"
            >
              <UploadImg />
            </div>
          </div>
          <p class="personalizacion-interfaz__text-isotipo text-end mb-0">
            Vista previa
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UploadImg from "../UploadImg.vue";
import IsotipoInfo from "./IsotipoInfo.vue";
export default {
  components: { UploadImg, IsotipoInfo },
  data() {
    return {
      colors: [
        "#2c3e50",
        "#6c6cff",
        "#52cec1",
        "#357bf3",
        "#4bbe61",
        "#f6c700",
        "#f26f28",
        "#e64141",
        "#ec206b",
      ],
      selected_color: "",
      show_selected: "",
    };
  },
  methods: {
    selectColor(color_, index_) {
      this.selected_color = color_;
      this.show_selected = index_;
    },
  },
};
</script>

